import React, { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { makeStyles } from '@material-ui/core'
import { StaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}))

const Media = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useState(null)
  const [hoverText, setHoverText] = useState(null)

  const multi = [
    { name: 'broadcast', hoverText: '60 minutes<br />ABC news<br />Cbs this morning<br />CNNfn business unusual<br />Dateline NBC <br />Entertainment Tonight <br />ESPN <br />Fox News Channel <br />Good Morning America <br />Today show' },
    { name: 'print', hoverText: 'America West Airlines <br />Business Week <br />CFO <br />Child <br />Cnn Money <br />Conde Nast Traveler <br />Entrepreneur Magazine <br />Financial Times <br />Inc. <br />Ladies Home Journal <br />National Geographic <br />People Magazine <br />Red Herring <br />Success <br />Time Magazine <br />TV Guide' },
    { name: 'dailies', hoverText: 'Arizona Daily Star <br />Atlanta Journal Constitution <br />Baltimore Sun <br />Chicago Tribune <br />Chicago Sun Times <br />Cincinnati Post <br />Dallas Morning News <br />Denver Post <br />Detroit News <br />Greenwich Times <br />Hartford Courant <br />Investor\'S Business Daily <br />Los Angeles Times <br />New York Times <br />Newsday <br />Sacramento Bee <br />San Diego Union-Tribune <br />San Francisco Business Times <br />San Francisco Chronicle <br />San Francisco Examiner <br />San Jose Mercury News <br />Seattle Post Intelligencer <br />St. Louis Post Dispatch <br />Usa Today <br />Wall Street Journal <br />Washington Post <br />Washington Times' },
    { name: 'international', hoverText: 'Asia Corporate News <br />Calgary Sun (Canada) <br />Canada.com <br />CB News (France) <br />CBC Radio (Canada) <br />CBC TV (Canada) <br />Cbiz.Cn (China) <br />China Manufacturing Digest <br />Cinic.Org (China) <br />Correspondance De La Publicité (France) <br />CTV (Canada) <br />Edmonton Journal (Canada) <br />Financial Post (Canada) <br />Guardian Unlimited (Uk) <br />Journal Français (France) <br />L\'Express (France) <br />La Nouvelle République (France) <br />Le Figaro (France) Le Point (France) <br />Les Échos (France) <br />Libre Belgique (Belgium) <br />London Daily Telegraph <br />Marketing Week (London) <br />Media International (London) <br />Stratégies (France) <br />Toronto Star (Canada) <br />Wall Street Journal <br />Asia Winnipeg Sun (Canada)<br /> Yahoo! Finance Uk & Ireland Yahoo! <br />Finance Uk & Ireland ' },
    { name: 'trade', hoverText: 'Advertising Age <br />Adweek <br />American Venture <br />Auntminnie.com <br />Biotech Week <br />Brandweek <br />BTOB <br />Builder Online <br />Creativity <br />Dm News <br />Electric And Hybrid Vehicles Today <br />Franchise World <br />Gardening Life <br />Grounds Maintenance <br />Health &amp; Medicine Week <br />HP World <br />Landscape &amp; Irrigation <br />Law & Health Weekly <br />Lawn &amp; Landscape Life <br />Science Weekly <br />Lodging Hospitality <br />Medicine &amp; Law Weekly <br />Mommd.com <br />Patent Salon Worldwide <br />Responsemag.com <br />Science Daily <br />Scuba Times <br />Shoot <br />Travel And Leisure <br />Women Advancing BioScience<br /> Women\'s Health Weekly <br />Working Mother' },
    { name: 'news', hoverText: 'Awise.Org <br />CBSmarketwatch.com <br />Excite.com <br />Forbes.com <br />Hoover\'S Online <br />Msn Money <br />News.Nasdaq.com <br />Reuters <br />Scripps <br />Howard News Service <br />USAToday.com <br />WallStreetCity.com <br />Wings <br />Yahoo! Finance' },
  ]

  const classes = useStyles()

  const handlePopoverOpen = (event, popoverId) => {
    console.log({popoverId})
    const values = multi.filter((r) => r.name === popoverId )
    console.log('values: ', values)
    setName(values[0].name)
    setHoverText(values[0].hoverText)  
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => { 
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl);

  return (
    <Layout bgcolor="#d05519">
      <SEO title="Media" bgcolor="#d05519" />
      <h1>media placements</h1>
      <p>Respected by press and media for trustworthy, original messaging, <strong>MKL Public Relations</strong> offers clients an authentic and clear voice with which to address target audiences. From news releases, feature presentations, special commemorations to announcements, clients capitalize on our reputation as a reliable, principled source of information, while integrated, well-considered communication plans strengthen brands and market presence. In addition to providing a respected voice for clients statewide, <strong>MKL PR</strong> has generated extensive national and international coverage.</p>
      <h2>sample media placements</h2>
      <ul>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'broadcast')}>Broadcast</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'print')}>Print</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'dailies')}>Dailies</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'international')}>International</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'news')}>News outlets &amp; online</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'trade')}>Trade &amp; niche</Button></li>
      </ul>

      <Popover
        id={name} open={open} anchorEl={anchorEl} onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'left'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Typography className={classes.typography} dangerouslySetInnerHTML={{ __html: hoverText }} />
      </Popover>

    </Layout>
  )
}

export default Media
